// API
// ----------------------------------------------------------------------

export const FIREBASE_API = {
  apiKey: 'AIzaSyDqQcWL7-b2TulRKydnz7j66ejA3Wxo6vQ',
  authDomain: 'manuela-and-cedric.firebaseapp.com',
  databaseURL: 'https://manuela-and-cedric-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'manuela-and-cedric',
  storageBucket: 'manuela-and-cedric.appspot.com',
  messagingSenderId: '838531223831',
  appId: '1:838531223831:web:c37455eb42b341e6a82752',
  measurementId: 'G-VEB3N8WR45',
};

export const GOOGLE_MAPS_API = 'AIzaSyCyLG08zgfdhNNtb0wqvR23NLtKtFAO8iY';

export const CLOUDINARY_SDK = 'drl5yz6yo';

export const DATABASE_NAMES = {
  wishes: 'wishes',
  guests: 'guests',
  donations: 'donations',
  faq: 'faq',
};

// LAYOUT
// ----------------------------------------------------------------------

export const RESPONSIVE_INTRO_HEIGHT = 'calc(100vh - 50px)';
