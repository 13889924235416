import { Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const auth = useAuth();

  return auth.user ? (
    <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
      <Outlet />
    </Box>
  ) : null;
}
