import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';
//
import Footer from './Footer/Footer';
import Navigation from '../components/Navigation/Navigation';

// ----------------------------------------------------------------------

const Page = forwardRef(
  ({ children, title = '', disableFooter, centerPage, disableNav, navBackTarget, sx, ...other }, ref) => {
    const centerStyles = centerPage
      ? {
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }
      : null;

    const titleSuffix = 'Manuela & Cédric ❤️';
    const pageTitle = title ? `${title} - ${titleSuffix}` : titleSuffix;

    return (
      <Box ref={ref} sx={[{ ...centerStyles }, ...(Array.isArray(sx) ? sx : [sx])]} {...other}>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        {!disableNav && <Navigation backlinkTarget={navBackTarget} />}
        {children}
        {!disableFooter && <Footer />}
      </Box>
    );
  }
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  disableFooter: PropTypes.bool,
  centerPage: PropTypes.bool,
  disableNav: PropTypes.bool,
  navBackTarget: PropTypes.string,
  sx: PropTypes.object,
};

export default Page;
