import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import SplitScreenLayout from '../layouts/splitscreen';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },

    // Main Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <SplitScreenLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/wir-heiraten" replace />, index: true },
        { path: 'wir-heiraten', element: <WeddingPage /> },
        { path: 'bilder', element: <GalleryPage /> },
      ],
    },

    // Main Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <SplitScreenLayout mobileReduced />
        </AuthGuard>
      ),
      children: [
        {
          path: 'wunschliste',
          children: [
            { element: <Navigate to="/wunschliste/uebersicht" replace />, index: true },
            { path: 'uebersicht', element: <WishlistPage /> },
            { path: ':id', element: <WishlistDetailPage /> },
          ],
        },
      ],
    },

    // Admin Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/admin" replace />, index: true },
        { path: 'admin', element: <AdminPage /> },
        { path: 'admin/wishes', element: <WishesPage /> },
        { path: 'admin/faq', element: <AdminFaqPage /> },
      ],
    },

    // Other Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Login Pages
const Login = Loadable(lazy(() => import('../pages/auth/Login')));

// Content Pages
const WeddingPage = Loadable(lazy(() => import('../pages/Wedding')));
const WishlistPage = Loadable(lazy(() => import('../pages/Wishlist')));
const WishlistDetailPage = Loadable(lazy(() => import('../pages/WishlistDetail')));
const GalleryPage = Loadable(lazy(() => import('../pages/Gallery')));

// System Pages
const AdminPage = Loadable(lazy(() => import('../pages/Admin/Admin')));
const WishesPage = Loadable(lazy(() => import('../pages/Admin/Wishes')));
const AdminFaqPage = Loadable(lazy(() => import('../pages/Admin/Faq')));
const NotFound = Loadable(lazy(() => import('../pages/system/Page404')));
const Page500 = Loadable(lazy(() => import('../pages/system/Page500')));
