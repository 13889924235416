import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { RESPONSIVE_INTRO_HEIGHT } from '../../../config';
import IntroData from '../../../_data/intro.json';
import Carousel from '../../../components/Carousel/Carousel';

const LeftPanel = ({ mobileReduced }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        overflow: 'hidden',
        left: 0,
        top: 0,
        bottom: 0,
        height: RESPONSIVE_INTRO_HEIGHT,

        [theme.breakpoints.up('md')]: {
          position: 'fixed',
          width: '50%',
          height: 'auto',
        },
        [theme.breakpoints.up('lg')]: {
          width: '61%',
        },

        [theme.breakpoints.down('md')]: {
          display: mobileReduced ? 'none' : 'block',
        },
      }}
    >
      <Box
        sx={{
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundRepeat: 'no-repeat',
          height: RESPONSIVE_INTRO_HEIGHT,

          [theme.breakpoints.up('md')]: {
            height: '100%',
          },
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
          }}
        >
          <Carousel />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            margin: '10% 20% 11% 10%',
            color: (theme) => theme.palette.common.white,
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontFamily: (theme) => theme.typography.font_secondary,
              fontWeight: 400,
              textShadow: (theme) => `0 0 4px ${theme.palette.common.black}`,
            }}
          >
            {IntroData.title}
          </Typography>
          <Typography
            variant="h4"
            component="p"
            sx={{ fontWeight: 100, textShadow: (theme) => `0 0 4px ${theme.palette.common.black}` }}
          >
            {IntroData.description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

LeftPanel.propTypes = {
  mobileReduced: PropTypes.bool,
};

export default LeftPanel;
