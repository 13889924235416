import { useRef } from 'react';
import Slider from 'react-slick';
import ImagesData from '../../_data/images.json';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { CLOUDINARY_SDK } from '../../config';
import useResponsive from '../../hooks/useResponsive';
import breakpoints from '../../theme/breakpoints';

// ----------------------------------------------------------------------

const StyledSlider = styled(Slider)`
  height: 100% !important;

  .slick-list,
  .slick-track,
  .slick-slide div {
    height: 100% !important;
  }
`;

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
};

const getCarouselImageUrls = (img) => {
  return {
    srcSet: `"https://res.cloudinary.com/${CLOUDINARY_SDK}/image/upload/f_auto,q_auto,w_256/${img} 256w,
            https://res.cloudinary.com/${CLOUDINARY_SDK}/image/upload/f_auto,q_auto,w_512/${img} 512w,
            https://res.cloudinary.com/${CLOUDINARY_SDK}/image/upload/f_auto,q_auto,w_768/${img} 768w,
            https://res.cloudinary.com/${CLOUDINARY_SDK}/image/upload/f_auto,q_auto,w_1024/${img} 1024w,
            https://res.cloudinary.com/${CLOUDINARY_SDK}/image/upload/f_auto,q_auto,w_1280/${img} 1280w"`,
    src: `https://res.cloudinary.com/${CLOUDINARY_SDK}/image/upload/f_auto,q_auto,w_512/${img}`,
  };
};

export default function Carousel() {
  const carouselRef = useRef(null);
  const isAboveMd = useResponsive('up', breakpoints.values.md);
  let SHUFFLED_IMAGES;

  const IMAGES = ImagesData.carouselImages;

  const mobileImage = getCarouselImageUrls(IMAGES[0].src);

  if (isAboveMd) {
    SHUFFLED_IMAGES = shuffleArray(IMAGES);
  }

  const settings = {
    speed: 1000,
    autoplaySpeed: 7500,
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    lazyLoad: 'progressive',
    fade: true,
    cssEase: 'ease',
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return isAboveMd ? (
    <StyledSlider ref={carouselRef} {...settings}>
      {SHUFFLED_IMAGES.map((item) => {
        const carouselImage = getCarouselImageUrls(item.src);

        return (
          <Box
            key={item.id}
            component="img"
            srcSet={carouselImage.srcSet}
            src={carouselImage.src}
            sx={{ height: '100%', objectFit: 'cover' }}
            alt=""
          />
        );
      })}
    </StyledSlider>
  ) : (
    <Box
      component="img"
      srcSet={mobileImage.srcSet}
      src={mobileImage.src}
      sx={{ height: '100%', width: '100%', objectFit: 'cover' }}
      alt=""
    />
  );
}
