import { Cloudinary } from '@cloudinary/url-gen';

const getImageUrl = (name) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'drl5yz6yo',
    },
  });

  return cld.image(name).format('auto').quality('auto').setSuffix('img');
};

export { getImageUrl };
