import { Outlet } from 'react-router-dom';
import LeftPanel from './partials/LeftPanel';
import RightPanel from './partials/RightPanel';
import useAuth from '../../hooks/useAuth';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

export default function SplitScreenLayout({ mobileReduced }) {
  const auth = useAuth();

  return auth.user ? (
    <>
      <LeftPanel mobileReduced={mobileReduced} />
      <RightPanel>
        <Outlet />
      </RightPanel>
    </>
  ) : null;
}

SplitScreenLayout.propTypes = {
  mobileReduced: PropTypes.bool,
};
