import { pxToRem, responsiveFontSizes } from '../utils/getFontValue';

// ----------------------------------------------------------------------

const font_primary = 'Roboto, sans-serif'; // Google Font
const font_secondary = 'Dancing Script, sans-serif'; // Google Font
const fontWeightLight = 300;
const fontWeightRegular = 400;
const fontWeightMedium = 600;
const fontWeightBold = 700;

const typography = {
  fontFamily: font_primary,
  fontWeightLight,
  fontWeightRegular,
  fontWeightMedium,
  fontWeightBold,
  h1: {
    fontWeight: fontWeightMedium,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    letterSpacing: 2,
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },
  h2: {
    fontWeight: fontWeightMedium,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  },
  h3: {
    fontWeight: fontWeightMedium,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
  },
  h4: {
    fontWeight: fontWeightMedium,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  },
  h5: {
    fontWeight: fontWeightMedium,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
  },
  h6: {
    fontWeight: fontWeightMedium,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },
  subtitle1: {
    fontWeight: fontWeightRegular,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: fontWeightRegular,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body1: {
    lineHeight: 1.5,
    fontWeight: fontWeightLight,
    fontSize: pxToRem(16),
  },
  body2: {
    lineHeight: 22 / 14,
    fontWeight: fontWeightLight,
    fontSize: pxToRem(14),
  },
  caption: {
    lineHeight: 1.5,
    fontWeight: fontWeightLight,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: fontWeightMedium,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: fontWeightMedium,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
  },
  customTitle: {
    fontWeight: fontWeightMedium,
    lineHeight: 1.5,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 32, md: 36, lg: 40 }),
  },
};

export default { ...typography, font_primary, font_secondary };
// export { font_primary, font_secondary };
