import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

const RightPanel = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.common.white,
        color: (theme) => theme.palette.text.primary,
        ml: 'auto',
        textAlign: 'center',

        [theme.breakpoints.up('md')]: {
          position: 'static',
          width: '50%',
        },
        [theme.breakpoints.up('lg')]: {
          width: '39%',
        },
      }}
    >
      <Box sx={{ position: 'relative' }}>{children}</Box>
    </Box>
  );
};

RightPanel.propTypes = {
  children: PropTypes.node,
};

export default RightPanel;
