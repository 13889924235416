import { Menu as MuiMenu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';
import { Link } from 'react-router-dom';

const Menu = ({ open, anchorEl, setAnchorEl }) => {
  const { isAdminUser, logout } = useAuth();

  const handleItemClick = (target) => {
    const scrollToEl = document.getElementById(target);
    if (scrollToEl) {
      scrollToEl.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

      let scrollTimeout;
      addEventListener('scroll', function () {
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(function () {
          handleClose();
        }, 100);
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await logout();
  };

  return (
    <MuiMenu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      disableScrollLock={true}
      sx={{ borderRadius: 1.5 }}
    >
      <MenuItem onClick={() => handleItemClick('bilder')}>Bilder</MenuItem>
      <MenuItem onClick={() => handleItemClick('brautpaar')}>Brautpaar</MenuItem>
      <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
      {isAdminUser && (
        <MenuItem to="/admin" component={Link}>
          Admin
        </MenuItem>
      )}
    </MuiMenu>
  );
};

Menu.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.any,
  setAnchorEl: PropTypes.any,
};

export default Menu;
