import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import IntroData from '../../_data/intro.json';

const Footer = ({ sx }) => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        py={3}
        mt={8}
        sx={[{ borderTop: (theme) => `1px solid ${theme.palette.primary.main}` }, ...(Array.isArray(sx) ? sx : [sx])]}
      >
        <Typography
          variant="h5"
          component="p"
          sx={{ fontFamily: (theme) => theme.typography.font_secondary, fontWeight: 100 }}
        >
          Manuela & Cédric
        </Typography>
        <Typography variant="body1" component="p" sx={{ fontWeight: 100 }}>
          {IntroData.dateClean}
        </Typography>
      </Box>
    </>
  );
};

Footer.propTypes = {
  sx: PropTypes.object,
};

export default Footer;
