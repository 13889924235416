import { Box, Container, Typography } from '@mui/material';
import { AdvancedImage } from '@cloudinary/react';
// components
import Page from '../../components/Page';
// sections
import { LoginForm } from '../../sections/auth/login';
import { getImageUrl } from '../../utils/cloudinaryImage';

// ----------------------------------------------------------------------

export default function Login() {
  const loginImage = getImageUrl('login');

  const typographyStyles = {
    fontFamily: (theme) => theme.typography.font_secondary,
    color: (theme) => theme.palette.secondary.main,
    textAlign: 'center',
    mt: 0,
    mb: 0,
  };

  return (
    <Page
      title="Login"
      centerPage
      disableNav
      disableFooter
      sx={{
        overflow: 'hidden',
      }}
    >
      <Container maxWidth="xs" sx={{ textAlign: 'center' }}>
        <Box
          component={AdvancedImage}
          cldImg={loginImage}
          alt=""
          sx={{ width: '90%', height: 'auto', mb: -1, display: 'inline-block' }}
        />
        <Typography variant="h1" sx={{ ...typographyStyles }}>
          Wir sagen Ja!
        </Typography>
        <Typography variant="h2" component="h1" sx={{ ...typographyStyles, mb: 2 }}>
          Manuela & Cédric
        </Typography>
        <LoginForm />
      </Container>
    </Page>
  );
}
