import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { FIREBASE_API } from '../config';

// ----------------------------------------------------------------------

if (!firebase.apps.length) {
  firebase.initializeApp(FIREBASE_API);
}

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  isFgUser: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: true, user: user.multiFactor.user.email },
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, user: null },
          });
        }
      }),
    [dispatch]
  );

  const login = (password) => {
    let email = 'full-guest@manu-and-cedi.ch';

    if (password === 'manu.cedi.2023') {
      email = 'apero-guest@manu-and-cedi.ch';
    } else if (password === '2ueB*r_mF7pNnVaHCREH') {
      email = 'admin@manu-and-cedi.ch';
    }

    return firebase.auth().signInWithEmailAndPassword(email, password);
  };

  const logout = async () => {
    await firebase.auth().signOut();
  };

  const { user } = state;

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: user ? (user === 'full-guest@manu-and-cedi.ch' ? 'fgUser' : 'apUser') : undefined,
        isFgUser: user === 'full-guest@manu-and-cedi.ch',
        isAdminUser: user === 'admin@manu-and-cedi.ch',
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
