import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Fab } from '@mui/material';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Menu from './partials/Menu';

const Navigation = ({ backlinkTarget }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleNavClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const fabStyles = {
    position: 'fixed',
    top: (theme) => theme.spacing(4),
    left: (theme) => theme.spacing(4),
    zIndex: 999,
    borderRadius: 1.5,
    boxShadow: (theme) => theme.shadows[12],
  };

  return backlinkTarget ? (
    <Fab variant="extended" component={Link} to={backlinkTarget} sx={{ ...fabStyles }}>
      <ArrowBackOutlinedIcon sx={{ mr: 1 }} />
      Zurück
    </Fab>
  ) : (
    <>
      <Fab variant="extended" onClick={handleNavClick} sx={{ ...fabStyles }}>
        <MenuOutlinedIcon sx={{ mr: 1 }} />
        Menu
      </Fab>
      <Menu open={open} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  );
};

Navigation.propTypes = {
  backlinkTarget: PropTypes.string,
};

export default Navigation;
